import '../css/app.css';
import 'lazysizes';
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist';
import EmblaCarousel from 'embla-carousel';

window.Alpine = Alpine;
Alpine.plugin(persist);

document.addEventListener('alpine:init', () => {
  Alpine.store('showAppFilter', {
    on: Alpine.$persist(false),
  });

  Alpine.store('showAppNews', {
    hasQueryParams: false,
    init() {
      // Überwachen Sie Änderungen an window.location.search
      const observer = new MutationObserver(() => {
        this.hasQueryParams = window.location.search !== '';
      });

      // Starten Sie die Beobachtung von window.location.search
      observer.observe(document.querySelector('html'), { subtree: true, childList: true });
    },
  });

  Alpine.store('history', {
    xUri: Alpine.$persist(''),
    readCookie() {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('lastSearchUri=')) {
          this.xUri = decodeURIComponent(cookie.substring('lastSearchUri='.length, cookie.length));
        } else {
          this.xUri = '';
        }
      }
    },
  });

  Alpine.effect(() => {
    const filterState = Alpine.store('showAppFilter').on;
    if (filterState) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  });
});
Alpine.start();

// Grab wrapper nodes
const rootNode = document.querySelector('.embla');
if (rootNode) {
  const viewportNode = rootNode.querySelector('.embla__viewport');

  // Grab button nodes
  const prevButtonNode = rootNode.querySelector('.embla__prev');
  const nextButtonNode = rootNode.querySelector('.embla__next');

  // Grab startIndex
  const dataIndex = rootNode.getAttribute('data-index');

  // Initialize the carousel
  const OPTIONS = { loop: true, startIndex: dataIndex };
  const embla = EmblaCarousel(viewportNode, OPTIONS);

  embla.on('init', (eventName) => {
    const slides = eventName.slideNodes();
    const active = slides[eventName.selectedScrollSnap()];
    slides.forEach((slide) => {
      slide.classList.remove('active');
    });
    active.classList.add('active');
  });
  embla.on('select', (eventName) => {
    const slides = eventName.slideNodes();
    const active = slides[eventName.selectedScrollSnap()];
    slides.forEach((slide) => {
      slide.classList.remove('active');
    });
    setTimeout(function () {
      active.classList.add('active');
    }, 300);
  });

  // Add click listeners
  prevButtonNode.addEventListener('click', embla.scrollPrev, false);
  nextButtonNode.addEventListener('click', embla.scrollNext, false);
}
